<template>
  <v-container>
    <v-layout row wrap justify-space-between class="mb-2">
      <v-flex xs6 sm4 md4 lg4 class="mb-1">
        <v-btn
          :color="$store.state.secondaryColor"
          outlined
          small
          @click="goBack"
        >
          <v-icon small left>mdi-arrow-left</v-icon>
          <span class="caption">{{ $t("back") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-space-between class="mb-6">
      <v-flex xs6 sm4 md4 lg4>
        <h2 class="headline text-capitalize">{{ $t("new expense claim") }}</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <v-flex xs6 sm4 md4 lg4 class="text-right">
        <v-btn
          small
          dark
          :loading="loading"
          :color="$store.state.secondaryColor"
          @click="uploadData"
        >
          <v-icon small left>save</v-icon>
          <span class="caption text-capitalize">{{ $t("submit") }}</span>
        </v-btn>
      </v-flex>
    </v-layout>
    <v-alert>
      <v-form ref="form">
        <v-layout row wrap class="caption mt-1">
          <v-flex xs12 sm4 md4 lg4 class="px-2">
            <span class="font-weight-medium">{{
              $t("proof of purchase")
            }}</span>
            <v-card class="pa-2" max-width="344">
              <v-img accept="image/*" :src="proof" height="200px"></v-img>
            </v-card>

            <input type="file" @change="previewPicture" class="caption mt-2" />
          </v-flex>
          <v-flex xs12 sm8 md8 lg8 class="px-2">
            <v-layout row wrap>
              <v-flex xs12 sm6 md4 lg4 class="px-1" v-if="userAccess == 1">
                <span class="font-weight-medium text-capitalize">{{
                  $t("employees")
                }}</span>
                <v-autocomplete
                  outlined
                  dense
                  item-text="names"
                  item-value="id"
                  :items="formattedEmployeeList"
                  v-model="expenseClaim.employeeId"
                  @change="setEmployeeId(expenseClaim.employeeId)"
                  :rules="rules.emptyField"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 class="px-1">
                <span class="font-weight-medium text-capitalize">{{
                  $t("amount")
                }}</span>
                <v-text-field
                  outlined
                  dense
                  @keypress="digits"
                  v-model="expenseClaim.amount"
                  :rules="rules.emptyField"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 sm6 md4 lg4 class="px-1">
                <span class="font-weight-medium text-capitalize">{{
                  $t("date")
                }}</span>
                <v-dialog
                  ref="dialog"
                  v-model="modal"
                  :return-value.sync="expenseClaim.dateTime"
                  persistent
                  width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="expenseClaim.dateTime"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      outlined
                      dense
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="expenseClaim.dateTime"
                    :max="todaysDate"
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="modal = false">
                      {{ $t("cancel") }}
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.dialog.save(expenseClaim.dateTime)"
                    >
                      {{ $t("ok") }}
                    </v-btn>
                  </v-date-picker>
                </v-dialog>
              </v-flex>
            </v-layout>
            <v-layout row wrap>
              <span class="font-weight-medium text-capitalize">{{
                $t("description")
              }}</span>
              <v-flex xs12 sm12 md12 lg12 class="px-1">
                <v-textarea
                  counter
                  outlined
                  v-model="expenseClaim.description"
                  :rules="rules.lengthLimit"
                ></v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-form>
    </v-alert>

    <v-snackbar
      v-model="snackSuccess"
      top
      center
      color="success lighten-5 success--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("expense claim added successfully") }}!</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="success darken-3"
          @click="snackSuccess = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="snackError"
      top
      center
      color="red lighten-5 error--text text--darken-3"
      :timeout="4000"
    >
      <span>{{ $t("could not add an expense claim. Try again later") }}</span>
      <template v-slot:action="{ attrs }">
        <v-btn
          v-bind="attrs"
          icon
          color="error darken-3"
          @click="snackError = false"
        >
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";
import { format } from "date-fns";
import { digits } from "@/plugins/functions";
import db from "@/plugins/fb";
import fb from "firebase/app";
export default {
  data: () => ({
    snackSuccess: false,
    snackError: false,
    userAccess: "",
    loading: false,
    modal: false,
    payroll: [],
    timeout: 2000,
    todaysDate: format(new Date(), "yyyy-MM-dd"),
    employeesList: [],

    proof:
      "https://cdn.pixabay.com/photo/2017/11/10/04/47/image-2935360_960_720.png",
    ratePerHour: "",
    expenseClaim: {
      proofOfPurchase: "",
      businessId: "",
      employeeId: "",
      amount: "",
      dateTime: "",
      description: "",
      names: {},
    },
  }),

  computed: {
    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
    selectedBusiness() {
      return this.$store.getters.getCurrentBusiness;
    },
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    imageName() {
      return this.expenseClaim.names.first + "/" + this.todaysDate;
    },
    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },
    selectedBusinessName() {
      let busId = this.ownersBusinesses.find(
        (item) => item.id == this.selectedBusiness
      );
      let busName = busId.businessName;
      return busName;
    },
    formattedEmployeeList() {
      var newList = [];
      for (var i = 0; i < this.employeesList.length; i++) {
        newList.push({
          id: this.employeesList[i].id,
          names:
            this.employeesList[i].names.first +
            " " +
            this.employeesList[i].names.last,
        });
      }
      return newList;
    },
    rules() {
      return {
        lengthLimit: [(v) => v.length <= 255 || this.$t("max 255 characters")],
        email: [
          (v) => !!v || this.$t("e-mail is required"),
          (v) => /.+@.+\..+/.test(v) || "Please enter a valid email",
        ],
        emptyField: [(v) => !!v || this.$t("please fill out this field")],
      };
    },
  },

  created() {
    this.getEmployeeDetails();
    this.getAllEmployees();
    this.checkAccessLevel();
  },
  methods: {
    digits,
    checkAccessLevel() {
      //Fetching current user details
      var data = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
      this.userAccess = data.accessLevel.isSuperAdmin
        ? 0
        : data.accessLevel.isOwner
        ? 1
        : data.accessLevel.isEmployee
        ? 2
        : 3;
    },

    getAllEmployees() {
      this.employeesList = [];
      if (this.selected) {
        db.collection("employees")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employeesList.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            this.loading = false;
          });
      } else {
        db.collection("employees")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employeesList.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            console.log(" epmployees list");
            console.log(this.employeesList);
            this.loading = false;
          });
      }
    },
    getEmployeeDetails() {
      if (this.$route.params.id) {
        db.collection("employees")
          .doc(this.$route.params.id)
          .get()
          .then((doc) => {
            this.employees = doc.data();
            this.loading = false;

            this.expenseClaim.avatar = this.employees.avatar;
            this.expenseClaim.employeeId = this.employees.employeeId;
            this.expenseClaim.businessId = this.employees.businessId;
            this.expenseClaim.names.first = this.employees.names.first;
            this.expenseClaim.names.middle = this.employees.names.middle;
            this.expenseClaim.names.last = this.employees.names.last;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },

    previewPicture(event) {
      this.file = event.target.files[0];
      this.proof = URL.createObjectURL(this.file);
    },

    setEmployeeId(id) {
      this.expenseClaim.employeeId = id;
      db.collection("employees")
        .doc(id)
        .get()
        .then((doc) => {
          this.employees = doc.data();
          this.loading = false;

          this.expenseClaim.avatar = this.employees.avatar;
          this.expenseClaim.employeeId = this.employees.employeeId;
          this.expenseClaim.businessId = this.employees.businessId;
          this.expenseClaim.names.first = this.employees.names.first;
          this.expenseClaim.names.middle = this.employees.names.middle;
          this.expenseClaim.names.last = this.employees.names.last;
        })
        .catch(() => {
          this.loading = false;
        });
    },

    uploadData() {
      this.expenseClaim.dateTime = new Date(this.expenseClaim.dateTime);

      if (this.$refs.form.validate()) {
        this.loading = true;

        let storageRef = fb.storage().ref("proofOfPurchase/" + this.imageName);
        let uploadTask = storageRef.put(this.file);

        uploadTask.on(
          "state_changed",
          () => {},
          (error) => {
            error.preventDefault();
          },
          () => {
            uploadTask.snapshot.ref.getDownloadURL().then((downloadLink) => {
              this.expenseClaim.proofOfPurchase = downloadLink;

              db.collection("expenseClaims")
                .add(this.expenseClaim)
                .then(() => {
                  this.loading = false;
                  this.snackSuccess = true;
                  setTimeout(() => this.goBack(), 2000);
                })
                .catch(() => {
                  this.snackError = true;
                });
            });
          }
        );
      }
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style></style>
